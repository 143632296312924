/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@popperjs/core';
import 'bootstrap';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { ENVIRONMENT } from '@environment';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (ENVIRONMENT.production) {
  enableProdMode();
}
