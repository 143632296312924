import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CompanyService } from '../services/company.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Company } from '../models/company.model';
import { GetUser } from '../models/user.model';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
})
export class EditCompanyComponent implements OnInit {
  companyForm: FormGroup;
  company: Company | null = null;
  user: GetUser | null = null;
  selectedFile: File | null = null;
  previewUrl: string | null = null;
  activeTab: string = 'details';
  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private authService: AuthService
  ) {
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      ceo: [''],
      employees: [''],
      website: [''],
      about: [''],
      addresses: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.loadCompanyData();
  }

  get addresses(): FormArray {
    return this.companyForm.get('addresses') as FormArray;
  }

  getAddressGroup(index: number): FormGroup {
    return this.addresses.at(index) as FormGroup;
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  loadCompanyData(): void {
    this.authService.initializeUser().subscribe((user) => {
      this.user = user;
      if (!this.user?.personalInfo?.company?.id) {
        console.error('No company id found in user info');
        return;
      } else {
        this.companyService
          .getCompany(this.user?.personalInfo?.company?.id)
          .subscribe((data) => {
            this.company = data;
            this.companyForm.patchValue({
              name: this.company?.attributes?.name,
              ceo: this.company?.attributes?.ceo,
              employees: this.company?.attributes?.employees,
              website: this.company?.attributes?.website,
              about: this.company?.attributes?.about,
            });

            this.company.attributes.addresses.forEach((address) => {
              this.addresses.push(
                this.fb.group({
                  streetNumber: [address.streetNumber],
                  street: [address.street],
                  city: [address.city],
                  postalCode: [address.postalCode],
                  country: [address.country],
                })
              );
            });
          });
      }
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  goBack(): void {
    this.router.navigate(['/company']);
  }

  saveCompany(): void {
    if (this.companyForm.valid && this.company) {
      const companyData = this.companyForm.value;
      if (this.selectedFile) {
        this.companyService.uploadLogo(this.selectedFile).subscribe((uploadResponse) => {
          companyData.logo = uploadResponse.id;
          this.updateCompany(companyData);
        });
      } else {
        this.updateCompany(companyData);
      }
    }
  }

  updateCompany(companyData: any): void {
    this.companyService
      .updateCompany(this.company!.id, companyData)
      .subscribe((response) => {
        this.router.navigate(['/company']);
      });
  }

  addAddress(): void {
    this.addresses.push(
      this.fb.group({
        streetNumber: [''],
        street: [''],
        city: [''],
        postalCode: [''],
        country: [''],
      })
    );
  }

  removeAddress(index: number): void {
    this.addresses.removeAt(index);
  }
}
