import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropdownComponentComponent } from './components/dropdown-component/dropdown-component.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './core/http-interceptor.service';
import { LayoutComponent } from './layout/layout.component';
import { ToastrModule } from 'ngx-toastr';
import { ApplicationComponent } from './box-application/application.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { TalentPoolComponent } from './talent-pool/talent-pool.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SelectedTagsComponent } from './talent-pool/component/selected-tag/selected-tags.component';
import { SingleSelectDropdownComponent } from './components/single-dropdown-component/single-select-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BoxCompanyComponent } from './box-company/box-company.component';
import { BoxFavouriteComponent } from './box-favourite/box-favourite.component';
import { CompanyComponent } from './company/company.component';
import { ManagerUpdateComponent } from './manager-update/manager-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { SettingsComponent } from './settings/settings.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegisterComponent } from './register/register.component';
import { MyApplicationComponent } from './my-application-component/my-application-component.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DropdownComponentComponent,
    LoginComponent,
    LayoutComponent,
    ApplicationComponent,
    HomepageComponent,
    ImgFallbackDirective,
    TalentPoolComponent,
    SelectedTagsComponent,
    SingleSelectDropdownComponent,
    BoxCompanyComponent,
    BoxFavouriteComponent,
    CompanyComponent,
    ManagerUpdateComponent,
    CreateEmployeeComponent,
    SettingsComponent,
    EditCompanyComponent,
    ForgotPasswordComponent,
    PasswordRecoveryComponent,
    RegisterComponent,
    MyApplicationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatIconModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');

    const storedLang = localStorage.getItem('preferredLanguage');
    const browserLang = translate.getBrowserLang();

    if (storedLang) {
      translate.use(storedLang);
    } else if (browserLang && browserLang.match(/en|de/)) {
      translate.use(browserLang);
      localStorage.setItem('preferredLanguage', browserLang);
    } else {
      translate.use('en');
      localStorage.setItem('preferredLanguage', 'en');
    }
  }
}
