import { Component, Input } from '@angular/core';
import { Favourite } from '../models/favourite.model';
import { Router } from '@angular/router';
import { FavouriteService } from '../services/favourite.service';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-box-favourite',
  templateUrl: './box-favourite.component.html',
  styleUrl: './box-favourite.component.scss',
})
export class BoxFavouriteComponent {
  @Input() favourite: Favourite[] = [];
  @Input() loaderFavourite = false;
  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;

  constructor(private router: Router, private favouriteService: FavouriteService) {}

  navigateToTalentPool(applicationId: number): void {
    this.router.navigate(['/talent-pool'], {
      fragment: `id_application=${applicationId}`,
    });
  }

  getFullImageUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) {
      return '';
    }
    return `${this.strapiBaseUrl}${relativeUrl}`;
  }

  viewAllFavourites(): void {
    this.favouriteService.setShowFavourites(true);
    this.router.navigate(['/talent-pool']);
  }
}
