import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../services/company.service';
import { User } from '../models/user.model';
import { COMPANY_EMPLOYEE_STATUS } from '../models/company.model';

@Component({
  selector: 'app-manager-update',
  templateUrl: './manager-update.component.html',
  styleUrl: './manager-update.component.scss',
})
export class ManagerUpdateComponent {
  manager: any | null = null;
  roles: any[] = [];
  selectedRole: any | null = null;
  StatusEnum = Object.values(COMPANY_EMPLOYEE_STATUS);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.companyService.getPersonalInfo(+id).subscribe((manager) => {
        this.manager = manager;
      });
    }
  }

  selectStatus(status: COMPANY_EMPLOYEE_STATUS): void {
    if (this.manager) {
      this.manager.attributes.status = status;
    }
  }

  updateManager(): void {
    if (this.manager) {
      this.companyService
        .updatePersonalInfo(this.manager.id, this.manager)
        .subscribe(() => {
          this.router.navigate(['/company']);
        });
    }
  }

  goBack(): void {
    this.router.navigate(['/company']);
  }
}
