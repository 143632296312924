import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { GetUser } from '../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {
  settingsForm: FormGroup;
  passwordForm: FormGroup;
  user: GetUser | null = null;
  activeTab: string = 'details';

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.settingsForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      givenName: ['', Validators.required],
      familyName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    });

    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadUserData();
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  loadUserData(): void {
    this.authService.initializeUser().subscribe((userData) => {
      this.user = userData;
      this.settingsForm.patchValue({
        email: this.user?.email,
        givenName: this.user?.personalInfo?.givenName,
        familyName: this.user?.personalInfo?.familyName,
        phoneNumber: this.user?.personalInfo?.phoneNumber,
      });
    });
  }

  saveSettings(): void {
    if (this.settingsForm.valid) {
      const formValues = this.settingsForm.value;

      this.updateUserAndPersonalInfo(formValues);
    }
  }

  updateUserAndPersonalInfo(formValues: any): void {
    const userData = {
      email: formValues.email,
    };

    const personalInfoData = {
      givenName: formValues.givenName,
      familyName: formValues.familyName,
      phoneNumber: formValues.phoneNumber,
      email: formValues.email,
    };

    if (!this.user || !this.user?.personalInfo?.id) {
      console.error('No user data found');
      return;
    }

    this.authService.updateUser(this.user.id, userData).subscribe(() => {
      this.authService
        .updatePersonalInfo(this.user?.personalInfo?.id!, personalInfoData)
        .subscribe(() => {
          this.router.navigate(['/dashboard']);
        });
    });
  }

  changePassword(): void {
    if (this.passwordForm.valid) {
      if (!this.user) {
        console.error('No user data found');
        return;
      }
      const formValues = this.passwordForm.value;
      if (formValues.newPassword !== formValues.confirmPassword) {
        this.toastr.error(this.translate.instant('NEW_PASSWORDS_DO_NOT_MATCH'));
        return;
      }
      this.authService
        .changePassword(
          formValues.currentPassword,
          formValues.newPassword,
          formValues.confirmPassword
        )
        .subscribe();
    } else {
      this.toastr.error(this.translate.instant('FORM_IS_INVALID'));
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('preferredLanguage', language);
    window.location.reload();
  }
}
