import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Company } from '../models/company.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { API_CONFIG } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  getCompany(companyId: number) {
    const url = `${API_CONFIG.endpoint}/companies/${companyId}?populate[manager][populate][user][populate][role]=true&populate[logo]=true&populate[addresses]=true`;
    return this.http.get<{ data: Company }>(url).pipe(
      map((response) => {
        return response.data;
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('COMPANY_LOADING_ERROR'));
        return throwError(() => new Error("Erreur lors du chargement de l'entreprise"));
      })
    );
  }

  deletePersonalInfo(personalInfoId: number) {
    const url = `${API_CONFIG.endpoint}/personal-infos/${personalInfoId}`;
    return this.http.delete(url).pipe(
      map(() => {
        this.toastr.success(this.translate.instant('MANAGER_DELETED'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('MANAGER_DELETE_ERROR'));
        return throwError(() => new Error('Error while deleting personal info'));
      })
    );
  }

  updatePersonalInfo(personalInfoId: number, personalInfo: any) {
    const url = `${API_CONFIG.endpoint}/personal-infos/${personalInfoId}`;
    const dataToUpdate = {
      data: {
        ...personalInfo.attributes,
      },
    };

    return this.http.put(url, dataToUpdate).pipe(
      map(() => {
        this.toastr.success(this.translate.instant('MANAGER_UPDATED'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('MANAGER_UPDATE_ERROR'));
        return throwError(() => new Error('Error while updating personal info'));
      })
    );
  }

  getPersonalInfo(personalInfoId: number) {
    const url = `${API_CONFIG.endpoint}/personal-infos/${personalInfoId}?populate[user][populate][role][populate]=true`;
    return this.http.get<{ data: any }>(url).pipe(
      map((response) => {
        return response.data;
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('PERSONAL_INFO_LOADING_ERROR'));
        return throwError(() => new Error('Error while getting personal info'));
      })
    );
  }

  getRoles() {
    const url = `${API_CONFIG.endpoint}/users-permissions/roles`;
    return this.http.get<{ roles: any }>(url).pipe(
      map((response) => {
        return response.roles;
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ROLES_LOADING_ERROR'));
        return throwError(() => new Error('Error while getting roles'));
      })
    );
  }

  createEmployee(companyId: number, payload: any) {
    const url = `${API_CONFIG.endpoint}/auth/registerEmployee`;
    return this.http.post(url, { ...payload, company: companyId }).pipe(
      map(() => {
        this.toastr.success(this.translate.instant('EMPLOYEE_CREATED_SUCCESS'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('EMPLOYEE_CREATE_ERROR'));
        return throwError(() => new Error('Error while creating employee'));
      })
    );
  }

  updateCompany(companyId: number, payload: any) {
    const url = `${API_CONFIG.endpoint}/companies/${companyId}`;
    return this.http.put(url, { data: { ...payload } }).pipe(
      map(() => {
        this.toastr.success(this.translate.instant('COMPANY_UPDATED_SUCCESS'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('COMPANY_UPDATE_ERROR'));
        return throwError(() => new Error('Error while updating company'));
      })
    );
  }

  uploadLogo(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('files', file);

    return this.http.post<any[]>(`${API_CONFIG.endpoint}/upload`, formData).pipe(
      map((response) => {
        this.toastr.success(this.translate.instant('LOGO_UPLOAD_SUCCESS'));
        return response[0];
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('LOGO_UPLOAD_ERROR'));
        return throwError(() => new Error('Error while uploading logo'));
      })
    );
  }
}
