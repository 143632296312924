import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { localizeData } from './_localizeData';
import { CreateFilterSearch, FilterSearch } from '../models/filter-search.model';
import { API_CONFIG } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class FilterSearchService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  getFilterSearch(personalId: string): Observable<FilterSearch[]> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const url = `${API_CONFIG.endpoint}/filter-searches?populate[degrees][populate][localizations]=true&populate[employment_types][populate][localizations]=true&populate[experience_ins][populate][localizations]=true&populate[languages][populate][localizations]=true&filters[personal_info][id][$eq]=${personalId}`;
    return this.http.get<{ data: FilterSearch[] }>(url).pipe(
      map((response) => {
        return response.data.map((filterSearch) =>
          this.localizeFilterSearch(filterSearch, lang)
        );
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('FILTER_SEARCH_LOADING_ERROR'));
        return throwError(
          () => new Error('Erreur lors du chargement des filtres de recherche')
        );
      })
    );
  }

  createFilterSearch(filterSearch: CreateFilterSearch): Observable<FilterSearch> {
    return this.http
      .post<FilterSearch>(`${API_CONFIG.endpoint}/filter-searches`, {
        data: filterSearch,
      })
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('FILTER_SEARCH_CREATION_SUCCESS'));
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('FILTER_SEARCH_CREATION_ERROR'));
          return throwError(
            () => new Error('Erreur lors de la création du filtre de recherche')
          );
        })
      );
  }

  removeFilterSearch(filterSearchId: number): Observable<void> {
    return this.http
      .delete<void>(`${API_CONFIG.endpoint}/filter-searches/${filterSearchId}`)
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('FILTER_SEARCH_DELETION_SUCCESS'));
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('FILTER_SEARCH_DELETION_ERROR'));
          return throwError(
            () => new Error('Erreur lors de la suppression du filtre de recherche')
          );
        })
      );
  }

  private localizeFilterSearch(filterSearch: FilterSearch, lang: string): FilterSearch {
    if (filterSearch.attributes?.degrees?.data) {
      filterSearch.attributes.degrees.data = localizeData(
        filterSearch.attributes.degrees.data,
        lang
      );
    }
    if (filterSearch.attributes?.employment_types?.data) {
      filterSearch.attributes.employment_types.data = localizeData(
        filterSearch.attributes.employment_types.data,
        lang
      );
    }
    if (filterSearch.attributes?.experience_ins?.data) {
      filterSearch.attributes.experience_ins.data = localizeData(
        filterSearch.attributes.experience_ins.data,
        lang
      );
    }
    if (filterSearch.attributes?.languages?.data) {
      filterSearch.attributes.languages.data = localizeData(
        filterSearch.attributes.languages.data,
        lang
      );
    }
    return filterSearch;
  }
}
