import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyService } from '../services/company.service';
import { Company } from '../models/company.model';
import { GetUser } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { GetLanguage, Language } from '../models/language.model';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
})
export class CreateEmployeeComponent implements OnInit {
  company: Company | null = null;
  user: GetUser | null = null;
  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;
  employeeForm: FormGroup;
  isLoading: boolean = false;
  languages: GetLanguage[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private authService: AuthService,
    private languageService: LanguageService
  ) {
    this.employeeForm = this.formBuilder.group({
      givenName: ['', Validators.required],
      familyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['hr', Validators.required],
      language: [null, Validators.required],
      phoneNumber: [''],
    });
  }

  ngOnInit(): void {
    this.authService.initializeUser().subscribe((user) => {
      this.user = user;
      if (!this.user?.personalInfo?.company?.id) {
        console.error('No company id found in user info');
        return;
      } else {
        this.companyService
          .getCompany(this.user?.personalInfo?.company?.id)
          .subscribe((company) => (this.company = company));
      }
    });
    this.languageService.getlanguages().subscribe((languages) => {
      this.languages = languages;
    });
  }

  onSubmit(): void {
    if (this.employeeForm.valid) {
      const employeeData = {
        ...this.employeeForm.value,
        language: Number(this.employeeForm.value.language),
      };
      this.isLoading = true;
      if (this.company === null) {
        this.isLoading = false;
        console.error('No company found');
        return;
      }
      this.companyService.createEmployee(this.company.id, employeeData).subscribe({
        next: () => {
          this.isLoading = false;
          this.router.navigate(['/company']);
        },
        error: () => {
          this.isLoading = false;
        },
      });
    } else {
      console.error('Form is invalid');
      this.employeeForm.markAllAsTouched();
    }
  }

  goBack(): void {
    this.router.navigate(['/company']);
  }
}
