<div class="dropdown" (click)="toggleDropdown()">
  <button
    class="btn btn-sm btn-outline-secondary dropdown-toggle rounded-5 px-3"
    type="button"
  >
    <span [ngClass]="{ 'fw-bold': selectedOption }">
      {{ getSelectedText() }}
    </span>
  </button>
  <div
    class="dropdown-menu mt-1"
    [class.show]="isDropdownOpen"
    style="white-space: nowrap; padding-right: 20px"
  >
    <div
      class="form-check"
      *ngFor="let option of options; let i = index"
      (click)="selectOption(option)"
    >
      <div class="px-2 py-1">
        <input
          class="form-check-input"
          type="radio"
          [id]="generateId(option.value, i)"
          [value]="option.value"
          [checked]="selectedOption?.value === option.value"
          name="experienceOptions"
        />
        <label class="form-check-label" [for]="generateId(option.value, i)">
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</div>
