export interface CompanyAttributes {
  about: string;
  ceo: string;
  createdAt: string;
  id: number;
  name: string;
  website: string;
  updatedAt: string;
  employees: number;
  addresses: [
    {
      id: number;
      city: string;
      country: string;
      postalCode: string;
      street: string;
      streetNumber: string;
    }
  ];
  manager: {
    data: [
      {
        id: number;
        attributes: {
          email: string;
          givenName: string;
          familyName: string;
          user: {
            data: {
              attributes: {
                role: {
                  data: {
                    id: number;
                    attributes: {
                      name: string;
                    };
                  };
                };
              };
            };
          };
        };
      }
    ];
  };
  logo: {
    data: {
      id: number;
      attributes: {
        url: string;
        name: string;
      };
    };
  };
}

export interface Company {
  id: number;
  attributes: CompanyAttributes;
}

export enum COMPANY_EMPLOYEE_STATUS {
  NEWLY = 'NEWLY',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
