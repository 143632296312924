import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../services/application.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Application } from '../models/application.model';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  applications: Application[] = [];
  isLoading: boolean = false;
  currentLang: string;

  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;

  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.currentLang = this.translate.currentLang || this.translate.getDefaultLang();
  }

  ngOnInit() {
    this.loadApplications();
  }

  loadApplications() {
    this.isLoading = true;
    this.applicationService.getApplications(5).subscribe(
      (applications) => {
        this.applications = applications;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  navigateToTalentPool(applicationId: number): void {
    this.router.navigate(['/talent-pool'], {
      fragment: `id_application=${applicationId}`,
    });
  }

  getFullImageUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) {
      return '';
    }
    return `${this.strapiBaseUrl}${relativeUrl}`;
  }
}
