<div *ngIf="!company" class="d-flex justify-content-center align-items-center h-100">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-container *ngIf="company">
  <ng-container *ngIf="company?.attributes?.name">
    <div class="fs-3">
      {{ company.attributes.name }}
    </div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.logo?.data?.attributes?.url">
    <img
      [src]="getFullImageUrl(company.attributes.logo.data.attributes.url)"
      [title]="company.attributes.logo.data.attributes.name"
      class="position-absolute top-0 end-0 bg-opacity-25 p-2"
      style="max-height: 120px; max-width: 300px"
      loading="lazy"
    />
  </ng-container>
  <ng-container>
    <div class="fw-bold mt-3 mb-2 fs-6">{{ 'GENERAL_INFORMATION' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.ceo">
    <div>
      {{ 'CEO' | translate }}: <span class="fw-medium">{{ company.attributes.ceo }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.createdAt">
    <div class="d-flex align-items-center gap-2">
      <div>{{ 'CREATED_ON_THE_PLATFORM' | translate }}:</div>
      <div class="fw-medium">
        {{ formatDate(company.attributes.createdAt) }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.employees">
    <div>
      {{ 'NUMBER_OF_EMPLOYEES' | translate }}:
      <span class="fw-medium">{{ company.attributes.employees }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.website">
    <div class="fw-bold mt-3 mb-2 fs-6">{{ 'WEBSITE' | translate }}</div>
    <div>
      <a
        [href]="addHttpsPrefix(company.attributes.website)"
        target="_blank"
        class="d-flex align-items-center gap-2 no-underline"
      >
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
        {{ company.attributes.website }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.addresses?.length ?? 0 > 0">
    <div class="fw-bold mt-3 mb-2 fs-6">{{ 'ADDRESSES' | translate }}</div>
    <ng-container *ngFor="let adress of company.attributes.addresses">
      <div class="d-flex align-items-center gap-2">
        <i class="fa-regular fa-map"></i>
        {{ adress.streetNumber }}
        {{ adress.street }}
        {{ adress.postalCode }}
        {{ adress.city }}
        {{ adress.country }}
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="company?.attributes?.manager?.data?.length ?? 0 > 0">
    <div class="fw-bold mt-3 mb-2 fs-6">{{ 'MANAGER' | translate }}</div>
    <div class="d-flex flex-column gap-2">
      <ng-container *ngFor="let manager of company.attributes.manager.data">
        <div class="d-flex align-items-center gap-2">
          <div class="user-initials-circle-light">
            {{
              manager.attributes.givenName[0].toUpperCase() +
                manager.attributes.familyName[0].toUpperCase()
            }}
          </div>
          {{ manager.attributes.givenName }} {{ manager.attributes.familyName }}
          <br />
          <a [href]="'mailto:' + manager.attributes.email">{{
            manager.attributes.email
          }}</a>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="d-flex mt-2">
    <button
      class="btn btn-primary rounded-5 ms-auto text-white btn-sm"
      [routerLink]="['/company']"
    >
      {{ 'VIEW_COMPANY' | translate }}
    </button>
  </div>
</ng-container>
