import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { finalize, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  pending = false;
  token: string | null = null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.registerForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validator: this.passwordsMatchValidator }
    );
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
  }

  passwordsMatchValidator(group: FormGroup): { [key: string]: boolean } | null {
    if (group.get('password')?.value !== group.get('confirmPassword')?.value) {
      return { mismatch: true };
    }
    return null;
  }

  submit(): void {
    if (this.registerForm.invalid) {
      this.toastr.error(this.translate.instant('FORM_INVALID'));
      return;
    }

    const password = this.registerForm.get('password')?.value;
    const confirmPassword = this.registerForm.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      this.toastr.error(this.translate.instant('PASSWORDS_DO_NOT_MATCH'));
      return;
    }

    if (!this.token) {
      this.toastr.error(this.translate.instant('INVALID_REGISTRATION_TOKEN'));
      return;
    }

    this.pending = true;
    this.authService
      .register(this.token, { password })
      .pipe(
        finalize(() => (this.pending = false)),
        take(1)
      )
      .subscribe({
        next: () => {
          // La redirection est gérée par le service
        },
        error: (error) => {
          this.toastr.error(this.translate.instant('REGISTRATION_FAILED'));
          console.error('Registration error:', error);
        },
      });
  }
}
