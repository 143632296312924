import {
  DEFAULT_API_VERSION,
  DEFAULT_API_VERSION_NUMERICAL,
  DEFAULT_REQUEST_TIMEOUT,
  DEFAULTS,
  IEnvironment,
} from './environment.default';

export const ENVIRONMENT: IEnvironment = {
  ...DEFAULTS,
  production: true,
  app: {
    availableLanguages: ['de', 'en'],
    fallbackLanguage: 'de',
    defaultLanguage: 'de',
    translationDir: '/assets/i18n',
  },
  api: {
    ssl: true,
    gatewayBaseUrl: 'https://api-v2.dev.nectanet-futurenet.actihosting.de',
    gatewayVersion: 1,
    apiVersion: DEFAULT_API_VERSION ?? 'v2',
    apiVersionNumerical: DEFAULT_API_VERSION_NUMERICAL ?? 2,
    fileServerBaseUrl: 'https://api-v2.dev.nectanet-futurenet.actihosting.de',
    staticBaseUrl: '/uploads',
    requestTimeout: DEFAULT_REQUEST_TIMEOUT ?? 30000,
    adminAppUrl: 'https://admin-portal.dev.nectanet-futurenet.actihosting.de',
  },
};
