import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { Application } from '../models/application.model';
import { TranslateService } from '@ngx-translate/core';
import { localizeData } from './_localizeData';
import { API_CONFIG } from './api.config';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  getApplications(limit: number = Infinity): Observable<Application[]> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const limitQuery = limit === Infinity ? '' : `&pagination[limit]=${limit}`;
    const url = `${API_CONFIG.endpoint}/applications?populate[personalInfo][populate][user][populate]=true&populate[languages][populate][language][populate][localizations][populate]=true&populate[workExperience][populate][experienceIns][populate][localizations][populate]=true&populate[lookingFors][populate][localizations][populate]=true&populate[workExperience][populate][experienceIns][populate][localizations][populate]=true&populate[degrees][populate][degrees][populate][localizations][populate]=true&populate[portrait][populate]=true&filters[personalInfo][status][$eq]=ACTIVE&sort=createdAt:desc${limitQuery}`;

    return this.http.get<{ data: Application[] }>(url).pipe(
      map((response) => {
        return response.data.map((app) => this.localizeApplication(app, lang));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_LOADING_APPLICATIONS'));
        return throwError(() => new Error('Erreur lors du chargement des applications'));
      })
    );
  }

  getApplicationById(applicationId: number): Observable<Application> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const url = `${API_CONFIG.endpoint}/applications/${applicationId}?populate[personalInfo][populate][user][populate]=true&populate[languages][populate][language][populate][localizations][populate]=true&populate[languages][populate][languageLevel][populate][localizations][populate]=true&populate[lookingFors][populate][localizations][populate]=true&populate[workExperience][populate][experienceIns][populate][localizations][populate]=true&populate[degrees][populate][degrees][populate][localizations][populate]=true&populate[portrait][populate]=true&populate[resume][populate]=true&populate[certificates][populate]=true`;

    return this.http.get<{ data: Application }>(url).pipe(
      map((response) => this.localizeApplication(response.data, lang)),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_LOADING_APPLICATION'));
        return throwError(() => new Error("Erreur lors du chargement de l'application"));
      })
    );
  }

  getApplicationByPersonalInfoId(personalInfo: number): Observable<Application | null> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const url = `${API_CONFIG.endpoint}/applications?filters[personalInfo][id][$eq]=${personalInfo}&populate[personalInfo][populate][user][populate]=true&populate[languages][populate][language][populate][localizations][populate]=true&populate[languages][populate][languageLevel][populate][localizations][populate]=true&populate[lookingFors][populate][localizations][populate]=true&populate[workExperience][populate][experienceIns][populate][localizations][populate]=true&populate[degrees][populate][degrees][populate][localizations][populate]=true&populate[portrait][populate]=true&populate[resume][populate]=true&populate[certificates][populate]=true`;

    return this.http.get<{ data: Application[] }>(url).pipe(
      map((response) => {
        if (response.data.length === 0) {
          return null;
        }
        return this.localizeApplication(response.data[0], lang);
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_LOADING_APPLICATION'));
        return throwError(() => new Error("Erreur lors du chargement de l'application"));
      })
    );
  }

  updateApplication(
    applicationId: number,
    payload: Partial<Application>
  ): Observable<Application> {
    const url = `${API_CONFIG.endpoint}/applications/${applicationId}`;

    return this.http.put<{ data: Application }>(url, { data: payload.attributes }).pipe(
      map((response) => {
        return response.data;
      }),
      tap(() => {
        this.toastr.success(this.translate.instant('APPLICATION_UPDATED_SUCCESSFULLY'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_UPDATING_APPLICATION'));
        return throwError(
          () => new Error("Erreur lors de la mise à jour de l'application")
        );
      })
    );
  }

  private localizeApplication(application: Application, lang: string): Application {
    if (application.attributes.workExperience?.experienceIns?.data) {
      application.attributes.workExperience.experienceIns.data = localizeData(
        application.attributes.workExperience.experienceIns.data,
        lang
      );
    }
    if (application.attributes.degrees?.degrees?.data) {
      application.attributes.degrees.degrees.data = localizeData(
        application.attributes.degrees.degrees.data,
        lang
      );
    }
    if (application.attributes.languages) {
      application.attributes.languages = localizeData(
        application.attributes.languages,
        lang,
        'language'
      );
      application.attributes.languages = localizeData(
        application.attributes.languages,
        lang,
        'languageLevel'
      );
    }
    if (application.attributes.lookingFors?.data) {
      application.attributes.lookingFors.data = localizeData(
        application.attributes.lookingFors.data,
        lang
      );
    }
    return application;
  }
}
