import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../services/company.service';
import { Company } from '../models/company.model';
import { GetUser } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  company: Company | null = null;
  user: GetUser | null = null;
  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;
  managerToDelete: any;
  deleteModalRef: NgbModalRef | undefined;

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.initializeUser().subscribe((user) => {
      this.user = user;
      if (!this.user?.personalInfo?.company?.id) {
        console.error('No company id found in user info');
        return;
      } else {
        this.companyService
          .getCompany(this.user?.personalInfo?.company?.id)
          .subscribe((company) => (this.company = company));
      }
    });
  }

  goToCreateEmployee(): void {
    this.router.navigate(['/create-employee']);
  }

  goToEditCompany(): void {
    this.router.navigate(['/edit-company']);
  }

  getFullImageUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) {
      return '';
    }
    return `${this.strapiBaseUrl}${relativeUrl}`;
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return 'Date not available';
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }

  addHttpsPrefix(url: string): string {
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    return url;
  }

  editManager(manager: any): void {
    this.router.navigate(['/manager-update', manager.id]);
  }

  openDeleteModal(manager: any, content: any): void {
    this.managerToDelete = manager;
    this.deleteModalRef = this.modalService.open(content);
  }

  confirmDelete(): void {
    if (this.managerToDelete) {
      this.companyService.deletePersonalInfo(this.managerToDelete.id).subscribe(() => {
        this.ngOnInit();
        if (this.deleteModalRef) {
          this.deleteModalRef.close();
        }
      });
    }
  }
}
