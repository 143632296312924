import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationService } from '../services/application.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { EmploymentTypeService } from '../services/employment-type.service';
import { DegreeService } from '../services/degree.service';
import { LanguageService } from '../services/language.service';
import { FavouriteService } from '../services/favourite.service';
import { AuthService } from '../services/auth.service';
import { DropdownOptionType } from '../components/dropdown-component/types/dropdown.interface';
import {
  SingleSelectDropdownComponent,
  SingleSelectOption,
} from '../components/single-dropdown-component/single-select-dropdown.component';
import { forkJoin, BehaviorSubject, Observable, of, firstValueFrom } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ExperienceInService } from '../services/experience-in.service';
import { DropdownComponentComponent } from '../components/dropdown-component/dropdown-component.component';
import { Application } from '../models/application.model';
import { EmploymentType } from '../models/employment-type.model';
import { DegreeAttributes } from '../models/degree.model';
import { GetExperience } from '../models/experience.model';
import { GetLanguage } from '../models/language.model';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { FilterSearchService } from '../services/filter-search.service';
import { FilterSearch } from '../models/filter-search.model';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-talent-pool',
  templateUrl: './talent-pool.component.html',
  styleUrls: ['./talent-pool.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TalentPoolComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChildren(DropdownComponentComponent)
  dropdownComponents!: QueryList<DropdownComponentComponent>;
  @ViewChildren(SingleSelectDropdownComponent)
  singleSelectDropdownComponents!: QueryList<SingleSelectDropdownComponent>;
  @ViewChild('contentContainer') contentContainer!: ElementRef;

  selectedApplicationId: number | null = null;
  applications: Application[] = [];
  filteredApplications: Application[] = [];
  employmentTypes: EmploymentType[] = [];
  degrees: DegreeAttributes[] = [];
  experienceIns: GetExperience[] = [];
  languages: GetLanguage[] = [];
  filterSearch: FilterSearch | null = null;
  isLoading: boolean = false;
  selectedApplication: Application | null = null;
  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;
  isMobile: boolean = false;
  filtersLoaded = false;
  dropdownsInitialized = false;
  userData: any = null;
  userPersonalId: any = null;
  favouriteApplication$ = new BehaviorSubject<any[]>([]);
  showFavouritesOnly: boolean = false;
  selectedMultipleFilters: { [key: string]: DropdownOptionType[] } = {
    employmentType: [],
    degreeProgram: [],
    experienceIn: [],
    language: [],
    industry: [],
  };
  selectedSingleFilters: { [key: string]: SingleSelectOption | null } = {
    experienceLevel: null,
  };
  experienceOptions: SingleSelectOption[] = [
    { name: this.translate.instant('EXPERIENCE_OPTIONS_NOVICE'), value: 'Least0' },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_2_YEARS'),
      value: 'Least2',
    },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_5_YEARS'),
      value: 'Least5',
    },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_10_YEARS'),
      value: 'Least10',
    },
  ];
  searchTerm: string = '';
  private exampleModal: Modal | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private employmentTypeService: EmploymentTypeService,
    private degreeService: DegreeService,
    private languageService: LanguageService,
    private experienceInService: ExperienceInService,
    private filterSearchService: FilterSearchService,
    private favouriteService: FavouriteService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private favouritesService: FavouriteService,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.router.url.includes('/talent-pool')) {
          this.favouritesService.setShowFavourites(false);
        }
      }
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        const id = parseInt(fragment.split('id_application=')[1], 10);
        if (!isNaN(id)) {
          this.selectedApplicationId = id;
          this.loadApplication(id);
        }
      } else {
        this.selectedApplicationId = null;
        this.selectedApplication = null;
      }
    });

    this.userData = this.authService.getUserId();
    this.userPersonalId = this.authService.getPersonalInfoId();
    this.loadFavourites();

    this.loadInitialData();

    const modalElement = document.getElementById('exampleModal');
    if (modalElement) {
      this.exampleModal = new Modal(modalElement);
    }

    this.favouritesService.showFavourites$.subscribe((showFavourites) => {
      this.showFavouritesOnly = showFavourites;
      this.filterApplications();
    });
  }

  ngOnDestroy(): void {
    this.favouritesService.setShowFavourites(false);
  }

  ngAfterViewInit(): void {
    this.loadFiltersFromLocalStorage();
  }

  ngAfterViewChecked(): void {
    if (
      !this.dropdownsInitialized &&
      this.dropdownComponents.length > 0 &&
      this.singleSelectDropdownComponents.length > 0
    ) {
      this.initializeDropdowns();
      this.dropdownsInitialized = true;
    }
  }

  loadInitialData(): void {
    this.isLoading = true;

    forkJoin({
      applications: this.applicationService.getApplications(),
      employmentTypes: this.employmentTypeService.getEmploymentTypes(),
      degrees: this.degreeService.getDegrees(),
      languages: this.languageService.getlanguages(),
      experienceIns: this.experienceInService.getExperienceIns(),
    }).subscribe(
      ({ applications, employmentTypes, degrees, languages, experienceIns }) => {
        this.applications = applications;
        this.filteredApplications = applications;
        this.employmentTypes = employmentTypes;
        this.degrees = degrees;
        this.languages = languages;
        this.experienceIns = experienceIns;
        this.loadFavourites();

        this.isLoading = false;
        this.loadFiltersFromLocalStorage();
        this.cd.detectChanges();
      }
    );
  }

  loadFavourites(): void {
    this.favouriteService.getFavourites(this.userData).subscribe((favourites) => {
      const favApplications = favourites.map((favourite) => ({
        applicationId: favourite.attributes.application.data.id,
        favouriteId: favourite.id,
      }));
      this.favouriteApplication$.next(favApplications);
    });
  }

  loadApplication(applicationId: number): void {
    this.applicationService.getApplicationById(applicationId).subscribe((application) => {
      this.selectedApplication = application;
    });
  }

  selectApplication(applicationId: number): void {
    this.selectedApplicationId = applicationId;
    this.loadApplication(applicationId);
    this.updateUrlFragment(applicationId);
    this.scrollToTop();
  }

  updateUrlFragment(applicationId: number): void {
    window.location.hash = `id_application=${applicationId}`;
  }

  clearSelectedApplication(): void {
    this.selectedApplicationId = null;
    this.selectedApplication = null;
    this.router.navigate([], { fragment: undefined });
  }

  getFullImageUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) {
      return '';
    }
    return `${this.strapiBaseUrl}${relativeUrl}`;
  }

  handleOptionChange({
    key,
    selectedOptions,
  }: {
    key: string;
    selectedOptions: DropdownOptionType[];
  }) {
    this.selectedMultipleFilters[key] = selectedOptions;
    this.filterApplications();
    this.saveFiltersToLocalStorage();
  }

  handleSingleSelectChange({
    key,
    selectedOption,
  }: {
    key: string;
    selectedOption: SingleSelectOption | null;
  }) {
    this.selectedSingleFilters[key] = selectedOption;
    this.filterApplications();
    this.saveFiltersToLocalStorage();
  }

  handleSelectionRemoved({
    key,
    option,
  }: {
    key: string;
    option: DropdownOptionType | SingleSelectOption;
  }) {
    if (this.selectedMultipleFilters[key]) {
      this.selectedMultipleFilters[key] = this.selectedMultipleFilters[key].filter(
        (selectedOption) => selectedOption.id !== (option as DropdownOptionType).id
      );

      const dropdownComponent = this.dropdownComponents.find(
        (dc) => dc.dropdownKey === key
      );
      if (dropdownComponent) {
        dropdownComponent.removeSelection(option as DropdownOptionType);
      }
    } else if (this.selectedSingleFilters[key]) {
      this.selectedSingleFilters[key] = null;

      const singleSelectDropdownComponent = this.singleSelectDropdownComponents.find(
        (dc) => dc.dropdownKey === key
      );
      if (singleSelectDropdownComponent) {
        singleSelectDropdownComponent.selectedOption = null;
        singleSelectDropdownComponent.emitSelectedOption();
      }
    }

    this.filterApplications();
  }

  onSearchChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchTerm = target.value.toLowerCase();
    this.filterApplications();
  }

  filterApplications() {
    this.filteredApplications = this.applications.filter((application: Application) => {
      const employmentTypeMatches =
        !this.selectedMultipleFilters['employmentType'] ||
        this.selectedMultipleFilters['employmentType'].every((type) =>
          application?.attributes?.lookingFors?.data?.some(
            (lookingFor) => lookingFor?.id === type.id
          )
        );

      const degreeProgramMatches =
        !this.selectedMultipleFilters['degreeProgram'] ||
        this.selectedMultipleFilters['degreeProgram'].every((degree) =>
          application?.attributes?.degrees?.degrees?.data?.some(
            (deg) => deg?.id === degree.id
          )
        );

      const experienceInsMatches =
        !this.selectedMultipleFilters['experienceIn'] ||
        this.selectedMultipleFilters['experienceIn'].every((experience) =>
          application?.attributes?.workExperience?.experienceIns?.data?.some(
            (exp) => exp?.id === experience.id
          )
        );

      const languageMatches =
        !this.selectedMultipleFilters['language'] ||
        this.selectedMultipleFilters['language'].every((language) =>
          application?.attributes?.languages?.some(
            (lang) => lang?.language?.data?.id === language.id
          )
        );

      const experienceLevelMatches =
        !this.selectedSingleFilters['experienceLevel'] ||
        !application?.attributes?.workExperience?.years ||
        application.attributes.workExperience.years ===
          (this.selectedSingleFilters['experienceLevel'] as SingleSelectOption).value;

      const isFavouriteMatch =
        !this.showFavouritesOnly || this.isFavouriteSync(application.id);

      const searchTermMatches =
        !this.searchTerm ||
        (
          application?.attributes?.personalInfo?.data?.attributes?.givenName.toLowerCase() +
          ' ' +
          application?.attributes?.personalInfo?.data?.attributes?.familyName.toLowerCase()
        ).includes(this.searchTerm.toLowerCase()) ||
        application?.attributes?.personalInfo?.data?.attributes?.email
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());

      //   ||
      // application?.attributes?.degrees?.degrees?.data?.some((deg) =>
      //   deg?.attributes?.name.toLowerCase().includes(this.searchTerm)
      // ) ||
      // application?.attributes?.workExperience?.experienceIns?.data?.some(
      //   (exp) =>
      //     exp?.attributes?.name.toLowerCase().includes(this.searchTerm)
      // );

      return (
        employmentTypeMatches &&
        degreeProgramMatches &&
        experienceInsMatches &&
        languageMatches &&
        experienceLevelMatches &&
        isFavouriteMatch &&
        searchTermMatches
      );
    });
  }

  checkSelectedFilters() {
    const selectedFilters = this.getSelectedFilters();
    if (
      selectedFilters.degrees.length === 0 &&
      selectedFilters.employment_types.length === 0 &&
      selectedFilters.languages.length === 0 &&
      selectedFilters.experience_ins.length === 0 &&
      selectedFilters.years.length === 0
    ) {
      this.toastr.error(this.translate.instant('ERROR_SELECT_ONE_FIELD'));
      return false;
    }
    return true;
  }

  getSelectedFilters(): {
    degrees: number[];
    employment_types: number[];
    languages: number[];
    experience_ins: number[];
    years: string[];
    personal_info: number;
  } {
    return {
      degrees: this.selectedMultipleFilters['degreeProgram']?.map((option) => option.id),
      employment_types: this.selectedMultipleFilters['employmentType']?.map(
        (option) => option.id
      ),
      languages: this.selectedMultipleFilters['language']?.map((option) => option.id),
      experience_ins: this.selectedMultipleFilters['experienceIn']?.map(
        (option) => option.id
      ),
      years: this.selectedSingleFilters['experienceLevel']
        ? [this.selectedSingleFilters['experienceLevel'].value]
        : [],
      personal_info: this.userPersonalId,
    };
  }

  saveFiltersToLocalStorage() {
    const filters = this.getSelectedFilters();
    localStorage.setItem('selectedFilters', JSON.stringify(filters));
  }

  loadFiltersFromLocalStorage() {
    const savedFilters = localStorage.getItem('selectedFilters');
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.selectedMultipleFilters['degreeProgram'] = this.degrees.filter((degree) =>
        filters.degrees?.includes(degree.id)
      );
      this.selectedMultipleFilters['employmentType'] = this.employmentTypes.filter(
        (type) => filters.employment_types.includes(type.id)
      );
      this.selectedMultipleFilters['language'] = this.languages.filter((language) =>
        filters.languages?.includes(language.id)
      );
      this.selectedMultipleFilters['experienceIn'] = this.experienceIns.filter(
        (experience) => filters.experience_Ins?.includes(experience.id)
      );
      this.selectedSingleFilters['experienceLevel'] =
        this.experienceOptions?.find((option) => option.value === filters.years[0]) ||
        null;

      // Met à jour les composants dropdown avec les filtres chargés
      this.dropdownComponents.forEach((dropdown) => {
        if (this.selectedMultipleFilters[dropdown.dropdownKey]) {
          dropdown.selectedOptions = {};
          this.selectedMultipleFilters[dropdown.dropdownKey].forEach((option) => {
            dropdown.selectedOptions[option.id] = true;
          });
        }
      });

      this.singleSelectDropdownComponents.forEach((dropdown) => {
        if (this.selectedSingleFilters[dropdown.dropdownKey]) {
          dropdown.selectedOption = this.selectedSingleFilters[dropdown.dropdownKey];
        }
      });

      this.filterApplications();
      this.cd.detectChanges();
    }
  }

  initializeDropdowns() {
    if (this.selectedMultipleFilters && this.selectedSingleFilters) {
      // Met à jour les composants dropdown avec les filtres chargés
      this.dropdownComponents.forEach((dropdown) => {
        if (this.selectedMultipleFilters[dropdown.dropdownKey]) {
          dropdown.selectedOptions = {};
          this.selectedMultipleFilters[dropdown.dropdownKey].forEach((option) => {
            dropdown.selectedOptions[option.id] = true;
          });
        }
      });

      this.singleSelectDropdownComponents.forEach((dropdown) => {
        if (this.selectedSingleFilters[dropdown.dropdownKey]) {
          dropdown.selectedOption = this.selectedSingleFilters[dropdown.dropdownKey];
        }
      });

      this.cd.detectChanges();
    }
  }

  toggleShowFavourites(): void {
    this.showFavouritesOnly = !this.showFavouritesOnly;
    this.favouritesService.setShowFavourites(this.showFavouritesOnly);
    this.filterApplications();
  }

  toggleFavourite(applicationId: number): void {
    const favourite = this.favouriteApplication$.value.find(
      (fav) => fav.applicationId === applicationId
    );

    if (!favourite) {
      const favouriteData = {
        user: this.userData,
        application: applicationId,
      };

      this.favouriteService
        .addFavourite(favouriteData.user, favouriteData.application)
        .pipe(
          finalize(() => {
            this.cd.detectChanges();
            this.loadFavourites();
          })
        )
        .subscribe(() => {});
    } else {
      this.favouriteService
        .removeFavourite(favourite.favouriteId)
        .pipe(
          finalize(() => {
            this.cd.detectChanges();
            this.loadFavourites();
          })
        )
        .subscribe(() => {});
    }
  }

  isFavourite(applicationId: number): Observable<boolean> {
    return this.favouriteApplication$.pipe(
      map((favourites) => !!favourites.find((fav) => fav.applicationId === applicationId))
    );
  }

  // Méthode synchrone pour vérifier si une application est en favoris
  isFavouriteSync(applicationId: number): boolean {
    return this.favouriteApplication$.value.some(
      (fav) => fav.applicationId === applicationId
    );
  }

  resetFilters() {
    this.selectedMultipleFilters = {
      employmentType: [],
      degreeProgram: [],
      experienceIn: [],
      language: [],
      industry: [],
    };
    this.selectedSingleFilters = {
      experienceLevel: null,
    };
    this.singleSelectDropdownComponents.forEach((dropdown) => {
      dropdown.selectedOption = null;
      dropdown.emitSelectedOption();
    });
    this.filterApplications();
    this.saveFiltersToLocalStorage();
    this.initializeDropdowns();
    this.dropdownsInitialized = true;

    this.router.navigate([], { fragment: '' });
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return 'Date not available';
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }

  openFile(url: string) {
    const fullUrl = `${this.strapiBaseUrl}${url}`;
    window.open(fullUrl, '_blank');
  }

  scrollToTop(): void {
    if (this.contentContainer) {
      this.contentContainer.nativeElement.scrollTop = 0;
    }
  }

  createFilterSearch(): void {
    if (!this.checkSelectedFilters()) {
      this.toastr.error(this.translate.instant('ERROR_SELECT_ONE_FIELD'));
    } else {
      const filterSearch = this.getSelectedFilters();
      this.filterSearchService.createFilterSearch(filterSearch).subscribe((filter) => {
        this.closeModalPerfect();
      });
    }
  }

  removeFilterSearch(): void {
    if (this.filterSearch) {
      this.filterSearchService
        .removeFilterSearch(this.filterSearch.id)
        .subscribe((filter) => {
          this.closeModalPerfect();
        });
    }
  }

  async openModalPerfect(): Promise<void> {
    if (this.exampleModal) {
      try {
        const filters = await firstValueFrom(
          this.filterSearchService.getFilterSearch(this.userPersonalId)
        );
        if (filters.length > 0) {
          this.filterSearch = filters[0];
        } else {
          this.filterSearch = null;
        }
        this.exampleModal.show();
      } catch (error) {
        console.error('Error loading filters:', error);
      }
    }
  }

  closeModalPerfect(): void {
    if (this.exampleModal) {
      this.exampleModal.hide();
    }
  }
}
