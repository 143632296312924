import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, tap, catchError, switchMap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { API_CONFIG } from './api.config';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isLoggedIn: boolean = false;
  private userInfo: any = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  initializeUser(): Observable<any> {
    const token = localStorage.getItem('jwt');
    if (token) {
      return this.fetchUserProfile().pipe(
        tap((profile) => {
          this.isLoggedIn = true;
          this.userInfo = profile;
        })
      );
    }
    return of(null);
  }

  login(identifier: string, password: string): Observable<boolean> {
    return this.http
      .post<{ jwt: string }>(`${API_CONFIG.endpoint}/auth/local`, {
        identifier,
        password,
      })
      .pipe(
        tap((response) => {
          localStorage.setItem('jwt', response.jwt);
          this.isLoggedIn = true;
          this.router.navigate(['/homepage']);
        }),
        switchMap(() =>
          this.fetchUserProfile().pipe(
            tap((profile) => {
              this.userInfo = profile;
            }),
            switchMap(() => of(true))
          )
        ),
        catchError((error) => {
          this.toastr.error(
            this.translate.instant('ERROR_INCORRECT_CREDENTIALS'),
            this.translate.instant('ERROR_LOGIN')
          );
          console.error('Error:', error);
          localStorage.removeItem('jwt');
          return of(false);
        })
      );
  }

  fetchUserProfile(): Observable<any> {
    return this.http.get<any>(`${API_CONFIG.endpoint}/users/me`).pipe(
      tap((profile) => {
        this.userInfo = profile;
      }),
      catchError((error) => {
        console.error('Failed to fetch user profile', error);
        return of(null);
      })
    );
  }

  logout() {
    this.isLoggedIn = false;
    this.userInfo = null;
    localStorage.removeItem('jwt');
    this.router.navigate(['/login']);
  }

  updateUser(userId: number, data: any): Observable<any> {
    const url = `${API_CONFIG.endpoint}/users/${userId}`;
    return this.http.put(url, data).pipe(
      tap(() => {
        this.toastr.success(this.translate.instant('SUCCESS_USER_UPDATED'));
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_UPDATING_USER'));
        return of(null);
      })
    );
  }

  updatePersonalInfo(personalInfoId: number, data: any): Observable<any> {
    const url = `${API_CONFIG.endpoint}/personal-infos/${personalInfoId}`;
    return this.http.put(url, { data }).pipe(
      tap(() => {}),
      catchError((error) => {
        this.toastr.error(this.translate.instant('ERROR_UPDATING_PERSONAL_INFO'));
        return of(null);
      })
    );
  }

  changePassword(
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  ): Observable<any> {
    const url = `${API_CONFIG.endpoint}/auth/change-password`;
    return this.http
      .post(url, {
        currentPassword,
        password,
        passwordConfirmation,
      })
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('SUCCESS_PASSWORD_CHANGED'));
          this.router.navigate(['/dashboard']);
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('ERROR_CHANGING_PASSWORD'));
          return of(null);
        })
      );
  }

  public requestRecoverPassword(email: string): Observable<any> {
    const url = `${API_CONFIG.endpoint}/auth/forgotPassword`;
    return this.http
      .post(
        url,
        { email },
        {
          withCredentials: false,
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('SUCCESS_RECOVERY_EMAIL_SENT'));
          this.router.navigate(['/login']);
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('ERROR_SENDING_RECOVERY_EMAIL'));
          return of(null);
        })
      );
  }

  public recoverPassword(payload: {
    code: string;
    password: string;
    passwordConfirmation: string;
  }): Observable<any> {
    const url = `${API_CONFIG.endpoint}/auth/reset-password`;
    return this.http
      .post(url, payload, {
        withCredentials: false,
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('SUCCESS_PASSWORD_RECOVERED'));
          this.router.navigate(['/login']);
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('ERROR_RECOVERING_PASSWORD'));
          return of(null);
        })
      );
  }

  register(id: string, payload: any) {
    const url = `${API_CONFIG.endpoint}/auth/finalizeRegistration?token=${id}`;
    return this.http
      .post(url, {
        password: payload.password,
      })
      .pipe(
        tap(() => {
          this.toastr.success(this.translate.instant('SUCCESS_ACCOUNT_CREATED'));
          this.router.navigate(['/login']);
        }),
        catchError((error) => {
          this.toastr.error(this.translate.instant('ERROR_CREATING_ACCOUNT'));
          return of(null);
        })
      );
  }

  getUserId(): number {
    return this.userInfo ? this.userInfo.id : null;
  }

  getPersonalInfoId(): any {
    return this.userInfo?.personalInfo?.id ? this.userInfo?.personalInfo?.id : null;
  }

  getAllUserInfo(): any {
    return this.userInfo;
  }

  getUserRole(): string {
    return this.userInfo ? this.userInfo.role : '';
  }

  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }
}
