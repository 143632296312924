import { Injectable } from '@angular/core';
import { Degree, DegreeAttributes } from '../models/degree.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { localizeData } from './_localizeData';
import { API_CONFIG } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class DegreeService {
  private isLoading: boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  getDegrees(): Observable<DegreeAttributes[]> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const url = `${API_CONFIG.endpoint}/degrees?populate[localizations]=true`;
    this.isLoading = true;

    return this.http.get<{ data: DegreeAttributes[] }>(url).pipe(
      map((response) => {
        return response.data;
      }),
      tap((degrees) => {
        localizeData(degrees, lang);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('error.DEGREES_LOADING_ERROR'));
        return throwError(
          () => new Error('Erreur lors du chargement des diplômes et de vos compétences')
        );
      })
    );
  }

  getLoadingStatus(): boolean {
    return this.isLoading;
  }
}
