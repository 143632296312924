import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DropdownOptionType } from './types/dropdown.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dropdown-component',
  templateUrl: './dropdown-component.component.html',
  styleUrls: ['./dropdown-component.component.scss'],
})
export class DropdownComponentComponent {
  @Input() dropdownKey: string = 'type';
  @Input() dropdownName: string = 'type';
  @Input() options: DropdownOptionType[] = [];
  @Output() selectionChange: EventEmitter<{
    key: string;
    selectedOptions: DropdownOptionType[];
  }> = new EventEmitter<{ key: string; selectedOptions: DropdownOptionType[] }>();

  isDropdownOpen = false;
  selectedOptions: { [key: number]: boolean } = {};

  constructor(private elementRef: ElementRef, private translate: TranslateService) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleOption(event: MouseEvent, option: DropdownOptionType): void {
    event.stopPropagation();
    this.selectedOptions[option.id] = !this.selectedOptions[option.id];
    this.emitSelectedOptions();
  }

  emitSelectedOptions() {
    const selected = this.options.filter((option) => this.selectedOptions[option.id]);
    this.selectionChange.emit({ key: this.dropdownKey, selectedOptions: selected });
  }

  getSelectedText() {
    const selectedCount = this.getSelectedCount();
    return selectedCount ? `${selectedCount} - ${this.dropdownName}` : this.dropdownName;
  }

  getSelectedCount(): number {
    return this.options.filter((option) => this.selectedOptions[option.id]).length;
  }

  removeSelection(option: DropdownOptionType): void {
    this.selectedOptions[option.id] = false;
    this.emitSelectedOptions();
  }

  generateId(optionId: number, index: number): string {
    return `${this.dropdownName.toLowerCase().replace(' ', '-')}-${optionId}-${index}`;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
}
