import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SingleSelectOption } from '@app/components/single-dropdown-component/single-select-dropdown.component';
import { Application } from '@app/models/application.model';
import { Degree, DegreeAttributes } from '@app/models/degree.model';
import { EmploymentType } from '@app/models/employment-type.model';
import { GetExperience } from '@app/models/experience.model';
import { GetLanguage } from '@app/models/language.model';
import { GetUser } from '@app/models/user.model';
import { API_CONFIG } from '@app/services/api.config';
import { ApplicationService } from '@app/services/application.service';
import { AuthService } from '@app/services/auth.service';
import { DegreeService } from '@app/services/degree.service';
import { EmploymentTypeService } from '@app/services/employment-type.service';
import { ExperienceInService } from '@app/services/experience-in.service';
import { LanguageService } from '@app/services/language.service';
import { ENVIRONMENT } from '@environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-application-component',
  templateUrl: './my-application-component.component.html',
  styleUrl: './my-application-component.component.scss',
})
export class MyApplicationComponent implements OnInit {
  activeSlide = 0;
  indicatorPosition = 0;
  loaderApplication = false;
  user: GetUser | null = null;
  application: Application | null = null;
  applicationForm: FormGroup;

  employmentTypes: EmploymentType[] = [];
  degreesProgram: DegreeAttributes[] = [];
  experiences: GetExperience[] = [];
  languages: GetLanguage[] = [];
  languageLevels: any[] = [];
  certificates: any[] = [];

  DEGREE_TYPE_TRANSLATIONS = {
    DEGREE: this.translate.instant('DEGREE'),
    STUDYING: this.translate.instant('STUDYING'),
    TRAINING_END: this.translate.instant('TRAINING_END'),
    TRAINING_PROGRESS: this.translate.instant('TRAINING_PROGRESS'),
  };

  experienceOptions: SingleSelectOption[] = [
    { name: this.translate.instant('EXPERIENCE_OPTIONS_NOVICE'), value: 'Least0' },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_2_YEARS'),
      value: 'Least2',
    },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_5_YEARS'),
      value: 'Least5',
    },
    {
      name: this.translate.instant('EXPERIENCE_OPTIONS_MORE_THAN_10_YEARS'),
      value: 'Least10',
    },
  ];

  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileCertificates') fileCertificates!: ElementRef;
  @ViewChild('fileResume') fileResume!: ElementRef;

  constructor(
    private applicationService: ApplicationService,
    private authService: AuthService,
    private fb: FormBuilder,
    private employmentTypeService: EmploymentTypeService,
    private degreeService: DegreeService,
    private translate: TranslateService,
    private experienceService: ExperienceInService,
    private languageService: LanguageService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.applicationForm = this.fb.group({
      beginDate: ['', Validators.required],
      endDate: [''],
      lookingFors: this.fb.array([]),
      degrees: this.fb.group({
        data: this.fb.array([]),
      }),
      degreeType: ['', Validators.required],
      years: ['', Validators.required],
      experiencesIns: this.fb.group({
        data: this.fb.array([]),
      }),
      otherExperience: [''],
      languages: this.fb.array([]),
      certificates: this.fb.array([]),
      resume: this.fb.group({
        id: ['', Validators.required],
        name: ['', Validators.required],
        url: [''],
      }),
      portrait: this.fb.group({
        id: [''],
        name: [''],
        url: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.loaderApplication = true;
    this.authService.initializeUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.applicationService
          .getApplicationByPersonalInfoId(user.personalInfo.id)
          .subscribe((application) => {
            if (application) {
              this.application = application;

              const beginDate = this.formatDate(application.attributes?.beginDate);
              const endDate = application.attributes?.endDate
                ? this.formatDate(application.attributes?.endDate)
                : '';

              this.applicationForm.patchValue({
                beginDate: beginDate,
                endDate: endDate,
              });

              this.employmentTypeService
                .getEmploymentTypes()
                .subscribe((employmentTypes) => {
                  this.employmentTypes = employmentTypes;
                  this.patchLookingFors(application.attributes?.lookingFors?.data);
                });

              this.degreeService.getDegrees().subscribe((degrees) => {
                this.degreesProgram = degrees;
                this.patchDegrees(application.attributes?.degrees);
              });

              this.experienceService.getExperienceIns().subscribe((experienceIns) => {
                this.experiences = experienceIns;
                this.patchExperiencesIns(
                  application.attributes?.workExperience?.experienceIns?.data
                );
              });

              this.languageService.getlanguages().subscribe((languages) => {
                this.languages = languages;
                if (application.attributes?.languages) {
                  this.patchLanguages(application.attributes.languages);
                }
              });

              this.languageService.getLanguageLevels().subscribe((levels) => {
                this.languageLevels = levels;
              });

              this.certificates = application.attributes?.certificates?.data;

              this.patchCertificates(application.attributes?.certificates?.data);
              this.patchResume(application.attributes?.resume);
              this.patchPortrait(application.attributes?.portrait);
            }
          });
        this.loaderApplication = false;
      }
    });
  }

  patchPortrait(portrait: any) {
    if (portrait) {
      this.applicationForm.patchValue({
        portrait: {
          id: portrait?.data?.id,
          name: portrait?.data?.attributes?.name,
          url: portrait?.data?.attributes?.url,
        },
      });
    }
  }

  patchResume(resume: any) {
    if (resume) {
      this.applicationForm.patchValue({
        resume: {
          id: resume?.data?.id,
          name: resume?.data?.attributes?.name,
          url: resume?.data?.attributes?.url,
        },
      });
    }
  }

  patchCertificates(certificates: any[]) {
    const certificatesArray = this.certificatesArray;
    certificatesArray.clear();

    if (!certificates) {
      return;
    }

    certificates.forEach((certificate) => {
      certificatesArray.push(
        this.fb.group({
          id: [certificate?.id],
          name: [certificate?.attributes?.name],
          url: [certificate?.attributes?.url],
        })
      );
    });
  }

  patchLanguages(languages: any[]) {
    const languagesArray = this.languagesArray;
    languagesArray.clear();

    languages.forEach((language: any) => {
      const languageGroup = this.fb.group({
        language: [language.language.data.id, Validators.required],
        languageLevel: [language.languageLevel.data.id, Validators.required],
      });
      languagesArray.push(languageGroup);
    });
  }

  patchExperiencesIns(experienceIns: any[]) {
    const experiencesInsArray = this.applicationForm.get(
      'experiencesIns.data'
    ) as FormArray;
    experienceIns.forEach((experienceIn) => {
      experiencesInsArray.push(this.fb.control(experienceIn.id));
    });
    this.applicationForm.patchValue({
      years: this.application?.attributes?.workExperience?.years,
      otherExperience:
        this.application?.attributes?.workExperience?.otherExperience || '',
    });
  }

  patchDegrees(degreePatch: Degree) {
    const degreesGroup = this.applicationForm.get('degrees') as FormGroup;
    const degreesArray = degreesGroup.get('data') as FormArray;
    degreePatch.degrees.data.forEach((degree) => {
      degreesArray.push(this.fb.control(degree.id));
    });
    this.applicationForm.patchValue({
      degreeType: this.application?.attributes.degrees.type,
    });
  }

  patchLookingFors(lookingFors: any[]) {
    const lookingForsArray = this.applicationForm.get('lookingFors') as FormArray;
    lookingFors.forEach((lookingFor) => {
      lookingForsArray.push(this.fb.control(lookingFor.id));
    });
  }

  formatDate(dateString: string | null): string | null {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  formatDateForAPI(dateString: string): string | null {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    return date.toISOString();
  }

  onPortraitFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);

      this.http
        .post(`${API_CONFIG.endpoint}/upload`, formData)
        .subscribe((response: any) => {
          const fileUrl = response[0]?.url;
          const fileName = response[0]?.name;
          this.applicationForm.get('portrait')?.patchValue({
            id: response[0]?.id,
            name: fileName,
            url: fileUrl,
          });
        });
    }
  }

  onResumeFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);

      this.http
        .post(`${API_CONFIG.endpoint}/upload`, formData)
        .subscribe((response: any) => {
          const fileUrl = response[0]?.url;
          const fileName = response[0]?.name;
          this.applicationForm.get('resume')?.patchValue({
            id: response[0]?.id,
            name: fileName,
            url: fileUrl,
          });
        });
    }
  }

  onFileChange(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);

      this.http
        .post(`${API_CONFIG.endpoint}/upload`, formData)
        .subscribe((response: any) => {
          const id = response[0]?.id;
          const fileUrl = response[0]?.url;
          const fileName = response[0]?.name;
          const certificatesArray = this.certificatesArray;
          certificatesArray
            .at(index)
            .patchValue({ id: id, url: fileUrl, name: fileName });
        });
    }
  }

  setActiveSlide(slideIndex: number) {
    this.activeSlide = slideIndex;
  }

  toggleExperienceIn(experienceInId: number) {
    const experiencesInsArray = this.applicationForm.get(
      'experiencesIns.data'
    ) as FormArray;
    const index = experiencesInsArray.controls.findIndex(
      (control) => control.value === experienceInId
    );

    if (index !== -1) {
      experiencesInsArray.removeAt(index);
    } else {
      experiencesInsArray.push(this.fb.control(experienceInId));
    }
  }

  toggleDegreeProgram(degreeId: number) {
    const degreesGroup = this.applicationForm.get('degrees') as FormGroup;
    const degreesArray = degreesGroup.get('data') as FormArray;
    const index = degreesArray.controls.findIndex(
      (control) => control.value === degreeId
    );

    if (index !== -1) {
      degreesArray.removeAt(index);
    } else {
      degreesArray.push(this.fb.control(degreeId));
    }
  }

  toggleLookingFor(employmentTypeId: number) {
    const lookingForsArray = this.applicationForm.get('lookingFors') as FormArray;
    const index = lookingForsArray.controls.findIndex(
      (control) => control.value === employmentTypeId
    );

    if (index !== -1) {
      lookingForsArray.removeAt(index);
    } else {
      lookingForsArray.push(this.fb.control(employmentTypeId));
    }
  }

  isExperienceIn(experienceInId: number): boolean {
    const experiencesInsArray = this.applicationForm.get(
      'experiencesIns.data'
    ) as FormArray;
    return experiencesInsArray.controls.some(
      (control) => control.value === experienceInId
    );
  }

  isDegreeProgram(degreeId: number): boolean {
    const degreesGroup = this.applicationForm.get('degrees') as FormGroup;
    const degreesArray = degreesGroup.get('data') as FormArray;
    if (degreesArray && degreesArray.controls) {
      return degreesArray.controls.some((control) => control.value === degreeId);
    }
    return false;
  }

  isLookingFor(employmentTypeId: number): boolean {
    const lookingForsArray = this.applicationForm.get('lookingFors') as FormArray;
    return lookingForsArray.controls.some(
      (control) => control.value === employmentTypeId
    );
  }

  getDegreeTypes(): string[] {
    return Object.keys(this.DEGREE_TYPE_TRANSLATIONS);
  }

  getDegreeTypeTranslation(type: string): string {
    return this.DEGREE_TYPE_TRANSLATIONS[
      type as keyof typeof this.DEGREE_TYPE_TRANSLATIONS
    ];
  }

  removeResume() {
    this.applicationForm.get('resume')?.reset({
      id: '',
      name: '',
      url: '',
    });
  }

  addLanguage() {
    const languagesArray = this.applicationForm.get('languages') as FormArray;
    languagesArray.push(
      this.fb.group({
        language: ['', Validators.required],
        languageLevel: ['', Validators.required],
      })
    );
  }

  addCertificate() {
    this.certificatesArray.push(
      this.fb.group({
        id: ['', Validators.required],
        name: ['', Validators.required],
        url: [''],
      })
    );
  }

  removeCertificate(index: number) {
    const certificatesArray = this.applicationForm.get('certificates') as FormArray;
    certificatesArray.removeAt(index);
  }

  updateResume(file: any) {
    this.applicationForm.patchValue({ resume: file });
  }

  updatePortrait(file: any) {
    this.applicationForm.patchValue({ portrait: file });
  }

  removeLanguage(index: number) {
    const languagesArray = this.applicationForm.get('languages') as FormArray;
    languagesArray.removeAt(index);
  }

  get languagesArray(): FormArray {
    return this.applicationForm.get('languages') as FormArray;
  }

  get certificatesArray(): FormArray {
    return this.applicationForm.get('certificates') as FormArray;
  }

  saveChanges() {
    if (!this.applicationForm.get('resume.id')?.value) {
      this.toastr.error(this.translate.instant('RESUME_REQUIRED'));
      return;
    }
    if (this.application && this.applicationForm.valid) {
      const updatedApplication = {
        ...this.application,
        attributes: {
          ...this.application.attributes,
          beginDate: this.formatDateForAPI(this.applicationForm.value.beginDate),
          endDate: this.applicationForm.value.endDate
            ? this.formatDateForAPI(this.applicationForm.value.endDate)
            : null,
          lookingFors: this.applicationForm.value.lookingFors,
          degrees: {
            ...this.application?.attributes?.degrees,
            degrees: this.applicationForm.value.degrees.data,
            type: this.applicationForm.value.degreeType,
          },
          workExperience: {
            ...this.application?.attributes?.workExperience,
            experienceIns: this.applicationForm.value.experiencesIns.data,
            years: this.applicationForm.value.years,
            otherExperience: this.applicationForm.value.otherExperience,
          },
          languages: this.applicationForm.value.languages.map((lang: any) => {
            return {
              language: Number(lang.language),
              languageLevel: Number(lang.languageLevel),
            };
          }),
          certificates: this.applicationForm.value.certificates.map(
            (certificate: any) => {
              return {
                id: Number(certificate.id),
              };
            }
          ),
          resume: Number(this.applicationForm.value.resume.id),
          portrait: Number(this.applicationForm.value.portrait.id),
        },
      };

      this.applicationService
        .updateApplication(this.application.id, updatedApplication as any)
        .subscribe();
    }
  }
}
