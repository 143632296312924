import { Component, Input } from '@angular/core';
import { Company } from '../models/company.model';
import { ENVIRONMENT } from '@environment';

@Component({
  selector: 'app-box-company',
  templateUrl: './box-company.component.html',
  styleUrl: './box-company.component.scss',
})
export class BoxCompanyComponent {
  @Input() company: Company | null = null;

  strapiBaseUrl: string = ENVIRONMENT.api.fileServerBaseUrl;

  constructor() {}

  getFullImageUrl(relativeUrl: string | undefined): string {
    if (!relativeUrl) {
      return '';
    }
    return `${this.strapiBaseUrl}${relativeUrl}`;
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return 'Date not available';
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }

  addHttpsPrefix(url: string): string {
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    return url;
  }
}
