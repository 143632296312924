<div class="container-xxl">
  <h2>{{ 'CHOOSE_LANGUAGES' | translate }}</h2>
  <div class="d-flex align-items-center gap-2">
    <button
      class="btn btn-sm btn-primary rounded-5 text-white"
      (click)="switchLanguage('en')"
    >
      {{ 'ENGLISH' | translate }}
    </button>
    <button
      class="btn btn-sm btn-primary rounded-5 text-white"
      (click)="switchLanguage('de')"
    >
      {{ 'DEUTSCH' | translate }}
    </button>
  </div>
  <h2 class="mt-4">{{ 'USER_SETTINGS' | translate }}</h2>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'details'"
          (click)="setActiveTab('details')"
          >{{ 'DETAILS' | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'password'"
          (click)="setActiveTab('password')"
          >{{ 'PASSWORD' | translate }}</a
        >
      </li>
    </ul>
  </div>

  <div [ngSwitch]="activeTab">
    <div *ngSwitchCase="'details'">
      <form [formGroup]="settingsForm" class="mb-5">
        <div class="mb-3">
          <label for="email" class="form-label">{{ 'EMAIL' | translate }}</label>
          <input
            type="email"
            id="email"
            formControlName="email"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="givenName" class="form-label rounded-5">{{
            'GIVEN_NAME' | translate
          }}</label>
          <input
            type="text"
            id="givenName"
            formControlName="givenName"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="familyName" class="form-label">{{
            'FAMILY_NAME' | translate
          }}</label>
          <input
            type="text"
            id="familyName"
            formControlName="familyName"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">{{
            'PHONE_NUMBER' | translate
          }}</label>
          <input
            type="text"
            id="phoneNumber"
            formControlName="phoneNumber"
            class="form-control rounded-5"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary text-white btn-sm rounded-5 px-3"
          (click)="saveSettings()"
        >
          {{ 'UPDATE_SETTINGS' | translate }}
        </button>
      </form>
    </div>

    <div *ngSwitchCase="'password'">
      <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" class="mb-5">
        <div class="mb-3">
          <label for="currentPassword" class="form-label">{{
            'CURRENT_PASSWORD' | translate
          }}</label>
          <input
            type="password"
            id="currentPassword"
            formControlName="currentPassword"
            class="form-control rounded-5"
          />
        </div>
        <div class="mb-3">
          <label for="newPassword" class="form-label">{{
            'NEW_PASSWORD' | translate
          }}</label>
          <input
            type="password"
            id="newPassword"
            formControlName="newPassword"
            class="form-control rounded-5"
            autocomplete="new-password"
          />
        </div>
        <div class="mb-3">
          <label for="confirmPassword" class="form-label">{{
            'CONFIRM_NEW_PASSWORD' | translate
          }}</label>
          <input
            type="password"
            id="confirmPassword"
            formControlName="confirmPassword"
            class="form-control rounded-5"
            autocomplete="new-password"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-5 text-white">
          {{ 'CHANGE_PASSWORD' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
