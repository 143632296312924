import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../services/company.service';
import { AuthService } from '../services/auth.service';
import { GetUser } from '../models/user.model';
import { Company } from '../models/company.model';
import { FavouriteService } from '../services/favourite.service';
import { Favourite } from '../models/favourite.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    private favouriteService: FavouriteService,
    private router: Router
  ) {}
  user: GetUser | null = null;
  company: Company | null = null;
  favourite: Favourite[] = [];
  loaderFavourite = false;

  ngOnInit(): void {
    this.loaderFavourite = true;
    this.authService.initializeUser().subscribe((user) => {
      this.user = user;
      if (!this.user?.personalInfo?.company?.id) {
        return;
      } else {
        this.companyService
          .getCompany(this.user?.personalInfo?.company?.id)
          .subscribe((company) => (this.company = company));
        this.favouriteService.getFavouriteList(this.user.id).subscribe((favourite) => {
          this.favourite = favourite;
          this.loaderFavourite = false;
        });
      }
    });
  }

  hasRole(roles: string[]): boolean {
    return roles.includes(this.user?.role || '');
  }

  goToCreateMyApplication(): void {
    this.router.navigate(['/me/application']);
  }
}
