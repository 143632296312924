import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetExperience } from '../models/experience.model';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { localizeData } from './_localizeData';
import { API_CONFIG } from './api.config';

@Injectable({ providedIn: 'root' })
export class ExperienceInService {
  private isLoading: boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  getExperienceIns(): Observable<GetExperience[]> {
    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    const url = `${API_CONFIG.endpoint}/experience-ins?populate[localizations]=true`;
    this.isLoading = true;

    return this.http.get<{ data: GetExperience[] }>(url).pipe(
      map((response) => response.data),
      tap((experienceIns) => {
        localizeData(experienceIns, lang);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
      catchError((error) => {
        this.toastr.error(this.translate.instant('EXPERIENCES_LOADING_ERROR'));
        return throwError(() => new Error('Erreur lors du chargement des expériences'));
      })
    );
  }

  getLoadingStatus(): boolean {
    return this.isLoading;
  }
}
