import { ENVIRONMENT } from '@environment';

interface IApiConfig {
  readonly endpoint: string;
  readonly readTimeout: number;
  readonly debounceTime: number;
}

export const API_CONFIG: IApiConfig = {
  debounceTime: 1000,
  endpoint: `${ENVIRONMENT.api.gatewayBaseUrl}/api`,
  readTimeout: ENVIRONMENT.api.requestTimeout,
};
