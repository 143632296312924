import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface SingleSelectOption {
  name: string;
  value: string;
}

@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.scss'],
})
export class SingleSelectDropdownComponent {
  @Input() dropdownKey: string = 'type';
  @Input() dropdownName: string = 'type';
  @Input() options: SingleSelectOption[] = [];
  @Output() selectionChange: EventEmitter<{
    key: string;
    selectedOption: SingleSelectOption | null;
  }> = new EventEmitter<{ key: string; selectedOption: SingleSelectOption | null }>();

  isDropdownOpen = false;
  selectedOption: SingleSelectOption | null = null;

  constructor(private elementRef: ElementRef, private translate: TranslateService) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: SingleSelectOption): void {
    this.selectedOption = option;
    this.isDropdownOpen = false;
    this.emitSelectedOption();
  }

  emitSelectedOption() {
    this.selectionChange.emit({
      key: this.dropdownKey,
      selectedOption: this.selectedOption,
    });
  }

  getSelectedText() {
    return this.selectedOption ? this.selectedOption.name : this.dropdownName;
  }

  generateId(optionValue: string, index: number): string {
    return `${this.dropdownName.toLowerCase().replace(' ', '-')}-${optionValue}-${index}`;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
}
