<div
  *ngIf="isLoading; else content"
  class="d-flex justify-content-center align-items-center h-100"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">loading...</span>
  </div>
</div>

<ng-template #content>
  <div *ngIf="applications.length > 0; else noApplications">
    <div class="d-grid gap-2 overflow-hidden">
      <div *ngFor="let application of applications">
        <div
          class="card-custom p-4 bg-light-discret rounded-2 d-flex align-items-center"
          (click)="navigateToTalentPool(application.id)"
        >
          <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center gap-2">
              <img
                [src]="
                  getFullImageUrl(
                    application?.attributes?.portrait?.data?.attributes?.formats
                      ?.thumbnail?.url
                  )
                "
                [title]="
                  application?.attributes?.portrait?.data?.attributes?.formats?.thumbnail
                    ?.name
                "
                class="rounded-circle img-thumbnail"
                loading="lazy"
                lcImgFallback
              />
              <div>
                <div class="fw-bold">
                  {{ application?.attributes?.personalInfo?.data?.attributes?.givenName }}
                  {{
                    application?.attributes?.personalInfo?.data?.attributes?.familyName
                  }}
                </div>
                <div class="text-secondary">
                  {{ application?.attributes?.personalInfo?.data?.attributes?.email }}
                </div>
              </div>
            </div>
            <div>
              <div
                class="d-flex flex-wrap gap-2"
                *ngIf="application?.attributes?.workExperience?.experienceIns?.data"
              >
                <div
                  class="tag__experience"
                  *ngFor="
                    let workExperience of application.attributes.workExperience
                      .experienceIns.data
                  "
                >
                  {{ workExperience.attributes.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-2">
        <button
          class="btn btn-primary rounded-5 ms-auto text-white btn-sm"
          [routerLink]="['/talent-pool']"
        >
          {{ 'VIEW_ALL_APPLICATIONS' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-template #noApplications>
    <div class="text-center">
      <p>{{ 'NO_APPLICATIONS_FOUND' }}</p>
    </div>
  </ng-template>
</ng-template>
