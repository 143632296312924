import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
class UserTokenService {
  getToken(): string | null {
    return localStorage.getItem('jwt');
  }
}

@Injectable({ providedIn: 'root' })
class PermissionsService {
  constructor(private router: Router, private userTokenService: UserTokenService) {}

  canActivate(): boolean {
    const token = this.userTokenService.getToken();
    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}

export const canActivateAuth: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(PermissionsService).canActivate();
};
