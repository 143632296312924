// Fonction qui permet de modifier les données d'un tableau en fonction de la langue
export function localizeData(data: any[], lang: string, key: string = ''): any[] {
  return data.map((item) => {
    const localizedItem = key
      ? item[key]?.data.attributes.localizations.data.find((loc: any) => loc.attributes.locale === lang)
      : item.attributes.localizations.data.find((loc: any) => loc.attributes.locale === lang);
    if (localizedItem) {
      if (key) {
        item[key].data.attributes = { ...localizedItem.attributes, localizations: item[key].data.attributes.localizations };
      } else {
        item.attributes = { ...localizedItem.attributes, localizations: item.attributes.localizations };
      }
    }
    return item;
  });
}
