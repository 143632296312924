<div class="bg-hard-discret">
  <router-outlet></router-outlet>
</div>

<style>
  .bg-hard-discret {
    background: url('../assets/images/futurenet_Picturemark_black_sRGB.png') no-repeat
      left bottom;
    background-size: 250px auto;
    position: relative;
    min-height: 100vh;
  }
</style>
