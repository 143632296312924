import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { canActivateAuth } from './guards/auth.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { LayoutComponent } from './layout/layout.component';
import { SettingsComponent } from './settings/settings.component';
import { canActivateRole } from './guards/role.guard';
import { TalentPoolComponent } from './talent-pool/talent-pool.component';
import { CompanyComponent } from './company/company.component';
import { ManagerUpdateComponent } from './manager-update/manager-update.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegisterComponent } from './register/register.component';
import { MyApplicationComponent } from './my-application-component/my-application-component.component';

const secureRoutesData = [
  {
    path: 'homepage',
    component: HomepageComponent,
    data: { title: 'Dashboard', roles: ['hr-admin', 'hr', 'applicant'] },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Paramètres', roles: ['hr-admin', 'hr', 'applicant'] },
  },
  {
    path: 'talent-pool',
    component: TalentPoolComponent,
    data: { title: 'Talent Pool', roles: ['hr-admin', 'hr'] },
  },
  {
    path: 'company',
    component: CompanyComponent,
    data: { title: 'Company', roles: ['hr-admin', 'hr'] },
  },
  {
    path: 'manager-update/:id',
    component: ManagerUpdateComponent,
    data: { title: 'Manager Update', roles: ['hr-admin', 'hr'] },
  },
  {
    path: 'create-employee',
    component: CreateEmployeeComponent,
    data: { title: 'Create Employee', roles: ['hr-admin', 'hr'] },
  },
  {
    path: 'edit-company',
    component: EditCompanyComponent,
    data: { title: 'Edit Company', roles: ['hr-admin', 'hr'] },
  },
  {
    path: 'me/application',
    component: MyApplicationComponent,
    data: { title: 'My Application', roles: ['applicant'] },
  },
];

const secureRoutes: Routes = secureRoutesData.map((route) => ({
  ...route,
  canActivate: [canActivateAuth, canActivateRole],
}));

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'recovery/forgot', component: ForgotPasswordComponent },
  { path: 'recovery/:token', component: PasswordRecoveryComponent },
  { path: 'register/:token/confirm', component: RegisterComponent },
  { path: '', redirectTo: '/homepage', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: secureRoutes,
  },
  { path: '**', redirectTo: '/homepage' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
