<div class="dropdown" (click)="toggleDropdown()">
  <button
    class="btn btn-sm btn-outline-secondary dropdown-toggle rounded-5 px-3"
    type="button"
  >
    <span [ngClass]="{ 'fw-bold': getSelectedCount() > 0 }">
      {{ getSelectedText() }}
    </span>
  </button>
  <div
    class="dropdown-menu mt-1 bg-white"
    [class.show]="isDropdownOpen"
    style="max-height: 250px; overflow-y: auto; white-space: nowrap; padding-right: 20px"
  >
    <div
      class="form-check w-auto"
      *ngFor="let option of options; let i = index"
      (click)="toggleOption($event, option)"
    >
      <div class="px-2 py-1">
        <input
          class="form-check-input"
          type="checkbox"
          [id]="generateId(option.id, i)"
          [value]="option.id"
          [(ngModel)]="selectedOptions[option.id]"
        />
        <label class="form-check-label">
          {{ option.attributes.name }}
        </label>
      </div>
    </div>
  </div>
</div>
