<div class="selected-tags mt-2">
  <!-- Affichage des filtres multiples -->
  <ng-container *ngFor="let filterKey of multipleFilterKeys">
    <span
      class="badge bg-primary me-2 rounded-5"
      *ngFor="let option of selectedMultipleFilters[filterKey]"
    >
      {{ option.attributes.name }}
      <span
        (click)="removeSelection(filterKey, option)"
        style="cursor: pointer; margin-left: 8px"
        >&times;</span
      >
    </span>
  </ng-container>

  <!-- Affichage des filtres uniques -->
  <ng-container *ngFor="let filterKey of singleFilterKeys">
    <span
      class="badge bg-primary me-2 rounded-5"
      *ngIf="selectedSingleFilters[filterKey]"
    >
      {{ selectedSingleFilters[filterKey]?.name }}
      <span
        (click)="removeSelection(filterKey, selectedSingleFilters[filterKey]!)"
        style="cursor: pointer; margin-left: 8px"
        >&times;</span
      >
    </span>
  </ng-container>
</div>
