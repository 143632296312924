import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownOptionType } from 'src/app/components/dropdown-component/types/dropdown.interface';
import { SingleSelectOption } from 'src/app/components/single-dropdown-component/single-select-dropdown.component';

@Component({
  selector: 'app-selected-tags',
  templateUrl: './selected-tags.component.html',
  styleUrls: ['./selected-tags.component.scss'],
})
export class SelectedTagsComponent {
  @Input() selectedMultipleFilters: { [key: string]: DropdownOptionType[] } = {};
  @Input() selectedSingleFilters: { [key: string]: SingleSelectOption | null } = {};
  @Output() selectionRemoved = new EventEmitter<{
    key: string;
    option: DropdownOptionType | SingleSelectOption;
  }>();

  get multipleFilterKeys(): string[] {
    return Object.keys(this.selectedMultipleFilters);
  }

  get singleFilterKeys(): string[] {
    return Object.keys(this.selectedSingleFilters);
  }

  removeSelection(key: string, option: DropdownOptionType | SingleSelectOption): void {
    this.selectionRemoved.emit({ key, option });
  }
}
