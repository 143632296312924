import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[lcImgFallback]',
})
export class ImgFallbackDirective {
  @Input() fallbackImage: string | undefined;

  constructor(private readonly _elRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const el: HTMLImageElement = this._elRef.nativeElement as HTMLImageElement;
    el.src = this.fallbackImage ?? '/assets/images/avatar-female.png';
  }
}
