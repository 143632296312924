<div class="d-flex align-items-center justify-content-center vh-100 position-relative">
  <div class="login-form d-flex flex-column flex-grow-1 gap-3">
    <div class="text-center mb-4">
      <img
        src="../../assets/images/futurenet_logo_text_black.png"
        alt="Logo"
        class="mb-3 w-75"
      />
    </div>
    <div class="text-center text-black-50">
      <h6 class="fs-6">{{ 'ENTER_NEW_PASSWORD' | translate }}</h6>
      <hr class="mb-4 mt-3" style="width: 80%; margin: auto" />
    </div>
    <div>
      <form [formGroup]="recoveryForm" (ngSubmit)="submit()">
        <div class="mb-3">
          <label for="password" class="form-label"
            >{{ 'NEW_PASSWORD' | translate }}<span class="text-danger">*</span></label
          >
          <input
            type="password"
            formControlName="password"
            class="form-control rounded-5 border-primary"
            autocomplete="new-password"
            id="password"
            required
          />
        </div>
        <div class="mb-3">
          <label for="confirmPassword" class="form-label"
            >{{ 'CONFIRM_NEW_PASSWORD' | translate
            }}<span class="text-danger">*</span></label
          >
          <input
            type="password"
            formControlName="confirmPassword"
            class="form-control rounded-5 border-primary"
            autocomplete="new-password"
            id="confirmPassword"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary my-3 rounded-5 w-100 text-white">
          <span>{{ 'SUBMIT' | translate }}</span>
        </button>
      </form>
    </div>
  </div>
  <div class="login-pattern"></div>
</div>
