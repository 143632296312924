import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  hasRequiredRole(requiredRoles: string[]): Observable<boolean> {
    return this.authService.initializeUser().pipe(
      switchMap(() => {
        const userRole = this.authService.getUserRole();
        const hasPermission = requiredRoles.includes(userRole);
        if (!hasPermission) {
          localStorage.removeItem('jwt');
          this.toastr.error(
            this.translate.instant('NO_ROLE_PERMISSION'),
            this.translate.instant('ERROR_PERMISSION')
          );

          return of(false);
        }
        return of(true);
      }),
      catchError(() => {
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}

export const canActivateRole: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const requiredRoles = route.data['roles'] as string[];
  return inject(PermissionsService).hasRequiredRole(requiredRoles);
};
