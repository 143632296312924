import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Collapse } from 'bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  userData: any = null;
  currentLanguage = localStorage.getItem('preferredLanguage') || 'en';

  constructor(private authService: AuthService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.authService.initializeUser().subscribe({
      next: (user) => {
        this.userData = user;
      },
      error: (error) => {
        console.error('Failed to load user data', error);
      },
    });
  }

  toggleMenu(): void {
    const navbarNav = document.getElementById('navbarNav');
    if (navbarNav) {
      const collapse = new Collapse(navbarNav, {
        toggle: false,
      });
      collapse.toggle();
    }
  }

  logout() {
    this.authService.logout();
  }

  closeMenu(): void {
    const navbarNav = document.getElementById('navbarNav');
    if (navbarNav) {
      new Collapse(navbarNav).hide();
    }
  }

  hasRole(roles: string[]): boolean {
    return roles.includes(this.userData.role);
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('preferredLanguage', language);
    window.location.reload();
  }
}
