<div
  *ngIf="loaderApplication"
  class="d-flex justify-content-center align-items-center"
  style="height: calc(100vh - 64px)"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-container *ngIf="!loaderApplication">
  <div class="container-xxl custom-container mt-3">
    <div class="d-flex justify-content-end align-items-center mb-4">
      <button class="btn btn-primary rounded-5 text-white" (click)="saveChanges()">
        {{ 'SAVE_CHANGES' | translate }}
      </button>
    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item cursor-pointer" role="preferences">
        <a
          class="nav-link"
          [class.active]="activeSlide === 0"
          (click)="setActiveSlide(0)"
          >{{ 'PREFERENCES' | translate }}</a
        >
      </li>
      <li class="nav-item cursor-pointer" role="education&experience">
        <a
          class="nav-link"
          [class.active]="activeSlide === 1"
          (click)="setActiveSlide(1)"
          >{{ 'EDUCATION_AND_EXPERIENCE' | translate }}</a
        >
      </li>
      <li class="nav-item cursor-pointer" role="languages">
        <a
          class="nav-link"
          [class.active]="activeSlide === 2"
          (click)="setActiveSlide(2)"
          >{{ 'LANGUAGE_SKILLS' | translate }}</a
        >
      </li>
      <li class="nav-item cursor-pointer" role="documents">
        <a
          class="nav-link"
          [class.active]="activeSlide === 3"
          (click)="setActiveSlide(3)"
        >
          {{ 'DOCUMENTS' | translate }}
        </a>
      </li>
    </ul>

    <ng-container class="tab-content mt-4">
      <ng-container *ngIf="activeSlide === 0">
        <div class="px-2 pt-4">
          <form [formGroup]="applicationForm" class="d-flex flex-column gap-3">
            <div class="d-flex flex-column gap-2">
              <p>{{ 'WHAT_ARE_YOU_LOOKING_FOR' | translate }}</p>
              <div class="d-flex align-items-center flex-wrap gap-2">
                <div
                  *ngFor="let employmentType of employmentTypes"
                  class="card employment-card"
                  [ngClass]="{
                    selected: isLookingFor(employmentType.id),
                    unselected: !isLookingFor(employmentType.id)
                  }"
                  (click)="toggleLookingFor(employmentType.id)"
                >
                  <div>
                    <p>
                      {{ employmentType?.attributes?.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="mb-1" for="beginDate">{{ 'BEGIN_DATE' | translate }}</label>
              <input
                type="date"
                class="form-control rounded-5"
                id="beginDate"
                formControlName="beginDate"
              />
            </div>
            <div class="form-group">
              <label class="mb-1" for="endDate">{{ 'END_DATE' | translate }}</label>
              <input
                type="date"
                class="form-control rounded-5"
                id="endDate"
                formControlName="endDate"
              />
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="activeSlide === 1">
        <div class="px-2 pt-4">
          <form [formGroup]="applicationForm" class="d-flex flex-column gap-3">
            <div class="form-group">
              <label class="mb-1" for="degreeType">{{
                'WHAT_TRAINING_DO_YOU_HAVE' | translate
              }}</label>
              <select
                id="degreeType"
                class="form-control rounded-5"
                formControlName="degreeType"
              >
                <option *ngFor="let type of getDegreeTypes()" [value]="type">
                  {{ getDegreeTypeTranslation(type) }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column gap-1">
              <label>{{ 'DO_YOU_HAVE_DEGREES' | translate }}</label>
              <div class="d-flex align-items-center flex-wrap gap-2">
                <div
                  *ngFor="let degreesPrograms of degreesProgram"
                  class="card degree-card"
                  [ngClass]="{
                    selected: isDegreeProgram(degreesPrograms.id),
                    unselected: !isDegreeProgram(degreesPrograms.id)
                  }"
                  (click)="toggleDegreeProgram(degreesPrograms.id)"
                >
                  <div>
                    {{ degreesPrograms?.attributes?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-1">
              <label for="years">
                {{ 'DO_YOU_ALREADY_HAVE_WORK_EXPERIENCE' | translate }}
              </label>
              <select id="years" class="form-control rounded-5" formControlName="years">
                <option *ngFor="let option of experienceOptions" [value]="option.value">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column gap-1">
              <label>{{ 'DO_YOU_HAVE_EXPERIENCE' | translate }} </label>
              <div class="d-flex align-items-center flex-wrap gap-2">
                <div
                  *ngFor="let experience of experiences"
                  class="card experience-card"
                  [ngClass]="{
                    selected: isExperienceIn(experience.id),
                    unselected: !isExperienceIn(experience.id)
                  }"
                  (click)="toggleExperienceIn(experience.id)"
                >
                  <div>
                    {{ experience?.attributes?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="mb-1" for="otherExperience">{{
                'DO_YOU_HAVE_ANY_OTHER_EXPERIENCE' | translate
              }}</label>
              <textarea
                id="otherExperience"
                class="form-control rounded-3"
                formControlName="otherExperience"
                rows="3"
              ></textarea>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="activeSlide === 2">
        <div class="px-2 pt-4">
          <form [formGroup]="applicationForm" class="d-flex flex-column gap-3">
            <div formArrayName="languages" class="d-flex flex-column gap-4">
              <div
                *ngFor="let langGroup of languagesArray.controls; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex flex-column gap-1">
                  <label for="language-{{ i }}">{{ 'LANGUAGE' | translate }}</label>
                  <select
                    id="language-{{ i }}"
                    class="form-control rounded-5"
                    formControlName="language"
                  >
                    <option *ngFor="let language of languages" [value]="language.id">
                      {{ language.attributes.name }}
                    </option>
                  </select>
                  <label for="languageLevel-{{ i }}">{{ 'LEVEL' | translate }}</label>
                  <select
                    id="languageLevel-{{ i }}"
                    class="form-control rounded-5"
                    formControlName="languageLevel"
                  >
                    <option *ngFor="let level of languageLevels" [value]="level.id">
                      {{ level.attributes.name }}
                    </option>
                  </select>
                  <div>
                    <button
                      type="button"
                      class="btn btn-outline-danger mt-2 btn-sm rounded-5"
                      (click)="removeLanguage(i)"
                    >
                      {{ 'REMOVE_LANGUAGE' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-outline-success btn-sm rounded-5"
                (click)="addLanguage()"
              >
                <i class="fa-solid fa-plus"></i> {{ 'ADD_LANGUAGE' | translate }}
              </button>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="activeSlide === 3">
        <div class="px-2 pt-4">
          <form [formGroup]="applicationForm" class="d-flex flex-column gap-3">
            <div formArrayName="certificates" class="d-flex flex-column gap-4">
              <div
                *ngFor="let certGroup of certificatesArray.controls; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex flex-column gap-1">
                  <div class="d-flex align-items-center gap-2">
                    <i class="fa-solid fa-file-lines"></i>
                    <div>{{ certGroup.get('name')?.value }}</div>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm rounded-5"
                      (click)="removeCertificate(i)"
                    >
                      {{ 'REMOVE_CERTIFICATE' | translate }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-sm rounded-5"
                      (click)="fileCertificates.click()"
                    >
                      {{ 'UPLOAD_CERTIFICATE' | translate }}
                    </button>
                    <input
                      type="file"
                      id="certificateFile-{{ i }}"
                      #fileCertificates
                      (change)="onFileChange($event, i)"
                      style="display: none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-sm btn-outline-success rounded-5"
                (click)="addCertificate()"
              >
                <i class="fa-solid fa-plus"></i>
                {{ 'ADD_CERTIFICATE' | translate }}
              </button>
            </div>
            <div class="d-flex flex-column gap-2 mt-3">
              <div class="d-flex align-items-center gap-2">
                <i class="fa-solid fa-file-alt"></i>
                <div>{{ applicationForm.get('resume')?.get('name')?.value }}</div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-danger rounded-5"
                  (click)="removeResume()"
                >
                  {{ 'REMOVE_RESUME' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-sm rounded-5"
                  (click)="fileResume.click()"
                >
                  {{ 'UPLOAD_RESUME' | translate }}
                </button>
                <input
                  type="file"
                  id="resumeFile"
                  (change)="onResumeFileChange($event)"
                  #fileResume
                  style="display: none"
                />
              </div>
            </div>
            <div class="d-flex flex-column gap-2 mt-3">
              <div *ngIf="applicationForm.get('portrait.url')?.value">
                <img
                  [src]="strapiBaseUrl + applicationForm.get('portrait.url')?.value"
                  [alt]="applicationForm.get('portrait.name')?.value"
                  style="max-height: 150px; display: block; margin-bottom: 10px"
                />
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-sm rounded-5"
                  (click)="fileInput.click()"
                >
                  {{ 'UPLOAD_PORTRAIT' | translate }}
                </button>
                <input
                  type="file"
                  (change)="onPortraitFileChange($event)"
                  #fileInput
                  style="display: none"
                />
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
