interface IHttpConnection {
  ssl: boolean;
  gatewayBaseUrl: string;
  gatewayVersion: string | number | null;
  apiVersion: string;
  apiVersionNumerical: number;
  requestTimeout: number;
  staticBaseUrl?: string;
  fileServerBaseUrl: string;
  adminAppUrl?: string;
}

export interface IApp {
  availableLanguages: string[];
  defaultLanguage: string;
  fallbackLanguage: string;
  translationDir: string;
}

export interface IEnvironment {
  debug?: boolean;
  production?: boolean;
  api: IHttpConnection;
  app: IApp;
}

export const DEFAULT_API_VERSION = 'v1';
export const DEFAULT_API_VERSION_NUMERICAL = 1;
export const DEFAULT_REQUEST_TIMEOUT = 30000;

export const DEFAULTS: IEnvironment = {
  production: false,
  debug: true,
  app: {
    availableLanguages: ['de', 'en'],
    fallbackLanguage: 'de',
    defaultLanguage: 'de',
    translationDir: '/assets/i18n',
  },
  api: {
    ssl: true,
    gatewayBaseUrl: 'http://localhost:1337',
    gatewayVersion: 1,
    apiVersion: DEFAULT_API_VERSION ?? 'api',
    apiVersionNumerical: DEFAULT_API_VERSION_NUMERICAL ?? 1,
    fileServerBaseUrl: 'http://localhost:1337',
    requestTimeout: DEFAULT_REQUEST_TIMEOUT ?? 30000,
    adminAppUrl: 'http://localhost:4200',
  },
};
